import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Parse from "html-react-parser";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
import "./style.css";

const Overview = () => {

    const data = useStaticQuery(graphql`
    query Overview {
        wpOverview{
          overviewMeta{
                  lessDescription
                  moreDescription
          }
        }
        wpThemeColor {
            themeColorMeta {
              primary
              secondary
            }
        }
      }
`)

    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fnReadMore = (action) => {
        if (action == 'more') {
            document.getElementById('readMoreBtn').style.display = 'none'
            document.getElementById('readMoreDiv').style.display = 'block'
            document.getElementById('CloseBtn').style.display = 'block'
        } else {
            document.getElementById('readMoreBtn').style.display = 'block'
            document.getElementById('readMoreDiv').style.display = 'none'
            document.getElementById('CloseBtn').style.display = 'none'
        }
    }

    return (
        <>
            <Box className="section" sx={{ flexGrow: 1 }} id="overview">
                <Grid container spacing={0} sx={{ px: { xs: 2, md: 10, lg: 30 }, py: { xs: 4, lg: 5 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            Overview
                        </Typography>
                        <hr />
                        <Box>
                            {Parse(data.wpOverview.overviewMeta.lessDescription)}
                        </Box>
                        <Button id="readMoreBtn" variant="contained" size="small" sx={{ float: 'right', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={() => fnReadMore("more")}>
                            Read More
                        </Button>
                        <Box id="readMoreDiv" style={{ display: 'none' }}>
                            {Parse(data.wpOverview.overviewMeta.moreDescription)}
                        </Box>
                        <Button id="CloseBtn" style={{ display: 'none' }} variant="contained" size="small" sx={{ float: 'right', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={() => fnReadMore("close")}>
                            Close
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center', mt: '2em' }}>
                        <Button sx={{ width: '205px', border: `1px solid ${secondarycolor}`, color: secondarycolor, "&:hover": { border: `1px solid ${secondarycolor}`, color: secondarycolor } }} onClick={handleOpen} variant="outlined" startIcon={<FileDownloadIcon className="bounce" />}>
                            Get Brochure
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent closeBtn={handleClose} />
            </Modal>
        </>
    );
};

export default Overview;