import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Play from '../../images/play-button.png';
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
import "./style.css";

const Video = () => {

    const data = useStaticQuery(graphql`
        query Video {
            wpVideo {
                videosMeta {
                  image {
                    sourceUrl
                  }
                  description
                  video
                }
            }
            wpThemeColor {
                themeColorMeta {
                  background
                }
            }
        }
    `)

    const background = data.wpThemeColor.themeColorMeta.background

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const img = data.wpVideo.videosMeta.image.sourceUrl;
    const video = data.wpVideo.videosMeta.video;
    const description = data.wpVideo.videosMeta.description;

    function fnVideo(video) {
        if (video != null && video != "") {
            return (
                <iframe title="" src={`https://www.youtube.com/embed/${video}`} frameborder="0" allowfullscreen="allowfullscreen"></iframe>
            )
        } else {
            return (
                <Box onClick={handleOpen} sx={{ display: 'table', width: '100%', boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)', borderRadius: '14px', backgroundImage: `url(${img})`, height: { xs: '20em', sm: '30em' }, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', cursor: 'pointer' }} >
                    <Box sx={{ display: 'table-cell', verticalAlign: 'middle' }}>
                        <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', color: '#fff', padding: '6px 10px', margin: 'auto', textAlign: 'center' }}>
                            <img src={Play} />
                            <Typography variant="subtitle1" sx={{ textAlign: 'center', color: '#fff', mb: 0 }}>
                                {description}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )
        }
    }

    return (
        <>
            <Box className="section" sx={{ flexGrow: 1 }} id="video">
                <Grid container spacing={0} sx={{ px: { xs: 2, md: 10, lg: 30 }, py: { xs: 4, lg: 5 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            Video Walk Through
                        </Typography>
                        <hr />
                        {fnVideo(video)}
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent closeBtn={handleClose} />
            </Modal>
        </>
    );
};

export default Video;