import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
import "./style.css";

function createData( name ) {
    return { name };
}

/*const rows = [
    createData('Adityaraj Breeze offers Luxurious 1 BHK & 2 BHK Apartments in Vikhroli East.'),
    createData('Breeze Vikhroli Offers Solid Earthquake Resistant RCC Structure Appartments.'),
    createData('Adityaraj Group offers Premium Big Vitrified Tiles in all Rooms of the Apartment.'),
    createData('Adityaraj Breeze Vikhroli offers Modern Lifestyle With Rooftop Amenities in Vikhroli.'),
    createData('Adityaraj Breeze Vikhroli enjoys a mesmerizing view of Mangroves and the City'),
    createData('Breeze Vikhroli is Surrounded by Natural Greenery and Landscape Parks.'),
    createData('Adityaraj Breeze Vikhroli Also Delivers Branded Lifts With ARD Systems.'),
    createData('Adityaraj Group offers a stunning home that offers all kinds of amenities and facilities.'),
    createData('Breeze enjoys close proximity to Vikhroli Railway Station and the upcoming Vikhroli-Ghonsoli Bridge.'),
    createData('Adityaraj Group provides comfort and convenience to suit every requirement as well as need.'),
    createData('Breeze Vikhroli East Offers Secured Gated Compound With Security Cabin.')
];*/

const Highlights = () => {

    const data = useStaticQuery(graphql`
    query Highlights {
        allWpHighlight(sort: {fields:projectHighlightMeta___description}) {
            edges {
                node {
                projectHighlightMeta {
                    description
                }
                }
            }
        }
        wpHighlightImage {
            highlightImageMeta {
              image {
                sourceUrl
              }
            }
        }
        wpThemeColor {
            themeColorMeta {
              primary
              secondary
            }
        }
    }
`)

const primarycolor = data.wpThemeColor.themeColorMeta.primary
const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

const img = data.wpHighlightImage.highlightImageMeta.image.sourceUrl;

const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

  const rows = data.allWpHighlight.edges.map((item,index) => (
        createData(item.node.projectHighlightMeta.description)
    ))


    return (
        <>
                <Box className="section" sx={{ flexGrow: 1}} id="highlights">
                    <Grid container spacing={0} sx={{ px: { xs: 2, md: 10 }, py: { xs: 4, md: 5 } }}>
                        <Grid item xs={12}>
                                <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                                Highlights
                                </Typography>
                                <hr />
                        </Grid>
                        <Grid item xs={12} md={6}>
                                <Box sx={{ backgroundImage: `url(${img})`, height: { xs: '350px', sm: '500px' }, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                                <TableContainer component={Paper} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', height: { xs: 'unset', md: '500px' } }}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button sx={{ width: '205px', border: `1px solid ${secondarycolor}`, color: secondarycolor, mt: 5, "&:hover": { border: `1px solid ${secondarycolor}`, color: secondarycolor } }} onClick={handleOpen} variant="outlined" startIcon={<FileDownloadIcon className="bounce" />}>
                                Get Brochure
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent closeBtn={handleClose} />
            </Modal>
        </>
    );
};

export default Highlights;