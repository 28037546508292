import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sheet from '../../images/costing-sheet.jpeg';
import "./style.css";

const settings = {
    infinite: false,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        }
    ]
};

const Price = () => {

    const data = useStaticQuery(graphql`
        query Price {
            wpThemeColor {
                themeColorMeta {
                  primary
                  secondary
                }
            }
            allWpPrice(sort: {fields: priceContentMeta___order}) {
                edges {
                  node {
                    title
                    priceContentMeta {
                      price
                      area
                    }
                  }
                }
            }
        }
    `)

    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <Box className="section" sx={{ flexGrow: 1 }} id="price">
                <Grid container spacing={0} sx={{ px: { xs: 5, md: 10 }, py: { xs: 4, md: 5 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 3 }}>
                            Project Price
                        </Typography>
                        <hr />
                        <SlickSlider {...settings}>
                            <Box>
                                <Box sx={{ display: 'table', width: '100%', textAlign: 'center', boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)', borderRadius: '14px', backgroundImage: `url(${Sheet})`, height: { xs: '16em', sm: '20em' }, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                                    <Box sx={{ display: 'table-cell', verticalAlign: 'bottom' }}>
                                        <Button variant="contained" sx={{ width: '205px', mb: 3, padding: '6px 30px', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={handleOpen} startIcon={<FileDownloadIcon className="bounce" />}>Get Cost Sheet</Button>
                                    </Box>
                                </Box>
                            </Box>
                            {data.allWpPrice.edges.map((item, index) => (
                                <Box>
                                    <Box sx={{ display: 'table', width: '100%', textAlign: 'center', boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)', borderRadius: '14px', height: { xs: '16em', sm: '20em' } }} >
                                        <Box sx={{ display: 'table-cell', verticalAlign: 'bottom' }}>
                                            <Typography variant="h6" sx={{ textAlign: 'left', textTransform: 'uppercase', px: { xs: 4, lg: 8 }, mb: { xs: 2, lg: 3 } }}>
                                                {parse(item.node.title)}
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ textAlign: 'left', textTransform: 'capitalize', px: { xs: 4, lg: 8 }, mb: { xs: 2, lg: 3 } }}>
                                                {parse(item.node.priceContentMeta.area)}
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ textAlign: 'left', textTransform: 'capitalize', px: { xs: 4, lg: 8 }, mb: { xs: 2, lg: 3 } }}>
                                                {parse(item.node.priceContentMeta.price)}
                                            </Typography>
                                            <Button variant="contained" sx={{ width: '205px', mb: 3, padding: '6px 30px', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={handleOpen} startIcon={<FileDownloadIcon className="bounce" />}>Get Cost Sheet</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </SlickSlider>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent closeBtn={handleClose} />
            </Modal>
        </>
    );
};

export default Price;