import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
import { motion } from "framer-motion";
import $ from "jquery";
import { ExitToApp } from "@mui/icons-material";

const settings = {
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: false,
    autoplay: true,
    autoplaySpeed: 3000,
};

const Slider = () => {

    const data = useStaticQuery(graphql`
        query Slider {
            allWpBanner {
                edges {
                    node {
                    bannerMeta {
                        imageUpload {
                        sourceUrl
                        }
                    }
                    }
                }
            }
            wpProject {
                projectMeta {
                  projectId
                  project
                }
            }
            wpOffer {
                offerMeta {
                  status
                  name
                  location
                  about
                  offer1
                  offer2
                  offer3
                  plans
                  priceText
                  price
                  reraNo
                }
            }
            wpThemeColor {
                themeColorMeta {
                  primary
                  secondary
                }
            }
        }
    `)

    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const projectid = data.wpProject.projectMeta.projectId
    const project = data.wpProject.projectMeta.project
    //const otp = data.wpProject.projectMeta.otp
        
    const fnSendMeDetail = (form) => {
        let url = document.location.href;
        url = decodeURIComponent(url);

        let source = "BLANK";

        //Birendra || Capture all utm codes in variable for crm post || 28-Jan-2023
        let utm_medium,utm_campaign,utm_id,utm_content,utm_term,gclid,fbclid;

        if(url.indexOf("?") != -1){
            const para = url.split("?")[1];
            
            
            let sources;
            
            //Birendra || Capture all utm codes in variable for crm post || 28-Jan-2023
            para.split("&").forEach(function(item) {
                if(item.indexOf("utm_source") != -1){
                    sources = item.split("=")[1];
                    localStorage.setItem("utm_source", sources);
                }
                else if(item.indexOf("utm_medium") != -1){
                    utm_medium = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_medium", utm_medium);
                }
                else if(item.indexOf("utm_campaign") != -1){
                    utm_campaign = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_campaign", utm_campaign);                    
                }                
                else if(item.indexOf("utm_id") != -1){
                    utm_id = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_id", utm_id);   
                }
                else if(item.indexOf("utm_content") != -1){
                    utm_content = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_content", utm_content);   
                }
                else if(item.indexOf("utm_term") != -1){
                    utm_term = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_term", utm_term);   
                }
                else if(item.indexOf("gclid") != -1){
                    gclid = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("gclid", gclid);   
                }
                else if(item.indexOf("fbclid") != -1){
                    fbclid = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("fbclid", gclid);   
                }                
            });


            if(sources == "GoogleSearch")
            source = "BLANK";
            else if(sources == "GoogleDisplay" || sources == "GoogleDiscovery")
            source = "BLANKD";
            else if(sources == "SMS")
            source = "ASY";
            else if(sources == "Facebook")
            source = "AFY";
            else if(sources == "Instagram")
            source = "ING";
        }
        else if(localStorage.hasOwnProperty('utm_source')) //Birendra || Capture all utm codes in variable for crm post || 28-Jan-2023
        {
             source = localStorage.getItem("utm_source");   
             utm_medium = localStorage.getItem("utm_medium").replace(/\+/g, ' ');   
             utm_campaign = localStorage.getItem("utm_campaign").replace(/\+/g, ' ');
             utm_id = localStorage.getItem("utm_id").replace(/\+/g, ' '); 
             utm_content = localStorage.getItem("utm_content").replace(/\+/g, ' '); 
             utm_term = localStorage.getItem("utm_term").replace(/\+/g, ' '); 
             gclid = localStorage.getItem("gclid").replace(/\+/g, ' '); 
             fbclid = localStorage.getItem("fbclid").replace(/\+/g, ' ');              
        }

        const name = document.querySelector('#' + form + ' [name="name"]').value
        const email = document.querySelector('#' + form + ' [name="email"]').value
        const mobile = document.querySelector('#' + form + ' [name="mobile"]').value.replace('-', '').replace(' ', '')
        
        const email_pattern = /^\w+([-+.'][^\s]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        const mobile_length = mobile.length

        if (name == "") {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'block'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = 'Please Enter Name'
            return false
        } else {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'none'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = ''
        }

        if (email == "") {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'block'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = 'Please Enter Email'
            return false
        } else if (!email_pattern.test(email)) {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'block'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = 'Please Enter Valid Email'
            return false
        } else {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'none'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = ''
        }

        //if (mobile.split(" ")[1] == undefined) {
        if (!mobile.trim().match('^([0|+[0-9]{1,5})?([7-9][0-9]{9})$')){    
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'block'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = 'Please Enter Mobile No.'
            return false
        } else {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'none'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = ''
        }

        // console.log(otp);
        // if(otp == 1){
        //     const pass = Math.floor(1000 + Math.random() * 9000);
        //     $.ajax({
        //         type: "post",
        //         url: 'https://api.textlocal.in/send/?apiKey=NjI2ZTQxNGEzMTMyNDE2MjMxMzI2NjcxNjY2MTQzNjI=&numbers='+mobile+'&sender=600010&message='+pass,
        //         success: function (data, status, jqXHR) {
        //         console.log(data);
        //         },
        //         error: function (jqXHR, status) {
        //             console.log(status);
        //         }
        //     });
        //     document.querySelector('#' + form + ' .detailForm').style.display = 'none'
        //     document.querySelector('#' + form + ' .otpForm').style.display = 'block'
        // }else{

            //Birendra || Capture all utm codes in variable for crm post || 28-Jan-2023
            var lead = {
                name: name,
                email: email,
                mobile: mobile,
                project: project,
                project_id: projectid,
                source: source,
                utm_medium: utm_medium,   
                utm_campaign: utm_campaign,
                utm_id: utm_id, 
                utm_content: utm_content,
                utm_term: utm_term,
                gclid: gclid,
                fbclid: fbclid                  
            }

            $.ajax({
                type: "post",
                url: 'https://mls-crm.manishalifespaces.com/api/add_lead',
                data: lead,
                success: function (data, status, jqXHR) {
                window.location.href = '/thanks';
                },
                error: function (jqXHR, status) {
                    console.log(status);
                }
            });
       //}

    }

    useEffect(() => {
        setTimeout(() => {
            $("#sliderLoader").addClass("slideHide");
            $("#mainSlider .slick-slider").removeClass("slideHide");
        }, 100);
      });

    function About(){
        if(data.wpOffer.offerMeta.about != ""){
            return(
                <Typography variant="subtitle1" sx={{ fontSize: '1.2em', textAlign: 'center', color: '#000', mb: 2 }}>
                    {parse(data.wpOffer.offerMeta.about)}
                </Typography>
            )
        }
    } 
    function Rera(){
        //alert(data.wpOffer.offerMeta.reraNo);
        if(data.wpOffer.offerMeta.reraNo != null){
            return(
                "RERA No : "+ data.wpOffer.offerMeta.reraNo
            )
        }
    }

    return (
        <>
            <Box sx={{ flexGrow: 1, mt: 8 }} id="mainSlider">
                <Grid container spacing={0}>
                    <Grid item xs={12} lg={9}>
                            <Box id="sliderLoader" sx={{ height: { xs: '30vh', sm: '50vh', md: '92vh' }, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <CircularProgress sx={{ visibility: 'hidden' }}/>
                            </Box>
                            <SlickSlider {...settings} className="slideHide">
                                {data.allWpBanner.edges.map((item,index) => (
                                    <div key={index}>
                                            <Box sx={{ height: { xs: '30vh', sm: '50vh', md: '92vh' }, backgroundImage: `url(${item.node.bannerMeta.imageUpload.sourceUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                                            </Box>
                                    </div>
                                ))}
                            </SlickSlider>
                            <Box
                                sx={{
                                    boxShadow: '0 10px 50px rgba(0, 0, 0, 0.25)',
                                    width: { xs: '85%', md: '400px' },
                                    padding: 2,
                                    margin: { xs: '20px auto', md: 'unset' },
                                    backgroundColor: '#fff',
                                    position: { xs: 'unset', md: 'absolute' },
                                    top: '82px',
                                    left: '15px',
                                    borderRadius: '10px',
                                    '&:hover': {
                                        cursor: 'pointer'
                                    },
                                }}
                            >
                                <Typography variant="subtitle1" sx={{ textAlign: 'center', textTransform: 'uppercase',background: primarycolor, color: '#fff', mb: 2 }}>
                                    {parse(data.wpOffer.offerMeta.status)}
                                </Typography>
                                <Typography variant="h6" sx={{ textAlign: 'center', textTransform: 'uppercase', color: '#000', mb: 0 }}>
                                    {parse(data.wpOffer.offerMeta.name)}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ textAlign: 'center', color: '#000', mb: 3 }}>
                                    {parse(data.wpOffer.offerMeta.location)}
                                </Typography>
                                {About()}
                                <Box className="animation-gradient" sx={{ padding: '20px', boxShadow: '0 1px 18px 4px rgba(0, 0, 0, 0.15) inset', borderRadius: '10px', border: '1px solid #ccc', mb: 1 }}>
                                    <motion.div initial={{ opacity: 0 }} animate={{ scale: [0, 1.2, 1], opacity: 1 }} transition={{ ease: "easeOut", duration: 1.5, repeat: Infinity, repeatDelay: 2 }}>
                                        <Typography variant="subtitle1" sx={{ textAlign: 'center', textTransform: 'uppercase', color: '#fff', mb: 0 }}>
                                            {parse(data.wpOffer.offerMeta.offer1)}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ textAlign: 'center', textTransform: 'capitalize', color: '#fff', mb: 0 }}>
                                            {parse(data.wpOffer.offerMeta.offer2)}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ textAlign: 'center', textTransform: 'capitalize', color: '#fff', mb: 0 }}>
                                            {parse(data.wpOffer.offerMeta.offer3)}
                                        </Typography>
                                    </motion.div>
                                </Box>
                                <Typography variant="body1" sx={{ textAlign: 'center', color: '#000', mb: 0 }}>
                                    {parse(data.wpOffer.offerMeta.plans)}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ textAlign: 'center', textTransform: 'capitalize', color: '#000', mb: 2 }}>
                                    {parse(data.wpOffer.offerMeta.priceText)}<span style={{ fontSize: '2em', color: primarycolor }}>{parse(data.wpOffer.offerMeta.price)}</span>
                                </Typography>
                                <Typography sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                                    <motion.div animate={{ rotate: [0, 6, 0, -6, 0] }} transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 2 }}>
                                        <Button variant="contained" sx={{ width: '205px', padding: '6px 30px', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={handleOpen}>
                                            Enquire Now
                                        </Button>
                                    </motion.div>
                                </Typography>
                                <Typography variant="body2" sx={{ textAlign: 'center', textTransform: 'uppercase', color: '#000', mb: 0 }}>
                                    {Rera()}
                                </Typography>
                            </Box>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                            <Box
                                component="form"
                                id="mainForm"
                                sx={{
                                    height: { xs: 'auto', lg: '86.5vh' },
                                    backgroundColor: primarycolor,
                                    padding: '20px 30px',
                                    '& .MuiTextField-root': { 
                                        my: 1,
                                        "&:hover": {
                                            '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#fff !important',
                                            }
                                        },
                                    },
                                    '& fieldset.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#fff !important',
                                    },
                                    '& MuiOutlinedInput-input': {
                                        "&:focus": {
                                            '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#fff !important',
                                            }
                                        }
                                    }
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Typography variant="subtitle1" sx={{ textAlign: 'center', textTransform: 'uppercase', color: '#fff', mb: {xs: 1, md: 0}, my: {xs: 1 , md: 3} }}>
                                Pre-Register here for Best Offers
                                </Typography>
                                <Stack className="detailForm" spacing={2}>
                                    <div>
                                        <TextField fullWidth
                                            sx={{ input: { color: '#fff' }, borderColor: '#fff', label: { color: '#fff' } }}
                                            name="name"
                                            placeholder="Name*"
                                        />
                                    </div>
                                    <div>
                                        <TextField fullWidth
                                            sx={{ input: { color: '#fff' }, label: { color: '#fff' } }}
                                            name="email"
                                            placeholder="E-mail Address*"
                                        />
                                    </div>
                                    <div>
                                    <PhoneInput
                                    country={'in'}
                                    inputProps={{
                                        name: 'mobile',
                                    }}
                                    />
                                    </div>
                                        <p style={{ color: 'red', margin: 'unset', display: 'none' }} class="formErrorMsg"></p>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button sx={{ width: '205px', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={() => fnSendMeDetail("mainForm")} variant="contained">
                                            Submit
                                        </Button>
                                    </div>
                                </Stack>
                                <Stack className="otpForm" spacing={2} sx={{ display: "none" }}>
                                    <div>
                                        <TextField fullWidth
                                            sx={{ input: { color: '#fff' }, borderColor: '#fff', label: { color: '#fff' } }}
                                            name="otp"
                                            placeholder="Enter Otp*"
                                        />
                                    </div>
                                        <p style={{ color: 'red', margin: 'unset', display: 'none' }} class="formErrorMsg"></p>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button sx={{ width: '205px', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={() => fnSendMeDetail("mainForm")} variant="contained">
                                            Submit
                                        </Button>
                                    </div>
                                </Stack>
                            </Box>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent closeBtn={handleClose} />
            </Modal>
        </>
    );
};

export default Slider;