import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
//import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import parse from 'html-react-parser';
import "./style.css";

var title = ""
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 6, px: { sm: 3, md: 5, lg: 10 } }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const settings = {
    infinite: false,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        }
    ]
};

const Plans = () => {

    const data = useStaticQuery(graphql`
        query Location {
            wpContact {
                contactMeta {
                  mapLink
                }
            }
            wpLocationImage {
                locationImagesMeta {
                  image {
                    sourceUrl
                  }
                }
            }
            allWpLocationContent(sort: {fields: locationContentMeta___orderNo}) {
                edges {
                  node {
                    locationContentMeta {
                      title
                      pointers
                    }
                  }
                }
            }
            allWpLocationAdvantage(sort: {fields: locationAdvantagesMeta___order}) {
                edges {
                  node {
                    locationAdvantagesMeta {
                      title
                      pointers
                      image {
                        sourceUrl
                      }
                    }
                  }
                }
            }
            wpThemeColor {
                themeColorMeta {
                  primary
                  secondary
                }
            }
        }
    `)

    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

    const map = data.wpContact.contactMeta.mapLink
    const image = data.wpLocationImage.locationImagesMeta.image.sourceUrl

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [expanded, setExpanded] = React.useState("panel0");

    const handleAChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Box className="section" sx={{ flexGrow: 1 }} id="location">
                <Grid container spacing={0} sx={{ px: { xs: 5, md: 10 }, py: { xs: 4, md: 5 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 3 }}>
                            Location
                        </Typography>
                        <hr />
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                sx={{
                                    width: { xs: '100%', sm: 'fit-content' },
                                    margin: 'auto',
                                    color: '#fff',
                                }}
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                indicatorColor="secondary"
                                textColor="inherit"
                                aria-label="full width tabs example"
                                centered
                            >
                                <Tab label="Location" {...a11yProps(0)} />
                                <Tab label="Connectivity" {...a11yProps(1)} />
                                <Tab label="Advantages" {...a11yProps(2)} />
                            </Tabs>
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <Box sx={{ height: {xs: "300px",lg: "600px"}, width: "100%", display: "inline-block", overflow: "hidden", marginBottom: "-20px" }}>
                                    <iframe  title="" src={map} frameborder="0" allowfullscreen="allowfullscreen"></iframe>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <Typography variant="h6">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} lg={6}>
                                            <img src={image} alt="" data-fancybox />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            {data.allWpLocationContent.edges.map((item, index) => (
                                                <Accordion expanded={expanded === 'panel' + index} onChange={handleAChange('panel' + index)}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`panel${index}a-content`}
                                                        id={`panel${index}a-header`}
                                                    >
                                                        <Typography>{item.node.locationContentMeta.title}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {parse(item.node.locationContentMeta.pointers)}
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                <SlickSlider {...settings}>
                                    {data.allWpLocationAdvantage.edges.map((item, index) => (
                                        <Box key={index}>
                                            <Box sx={{ boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)', borderRadius: '14px' }}>
                                                <Box sx={{ borderTopRightRadius: '14px', borderTopLeftRadius: '14px', backgroundImage: `url(${item.node.locationAdvantagesMeta.image.sourceUrl})`, height: { xs: '12em', sm: '12em' }, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                                                </Box>
                                                <Typography variant="h6" sx={{ backgroundColor: primarycolor, color: '#fff', padding: '6px 10px', margin: 'auto', textAlign: 'center' }}>
                                                    {item.node.locationAdvantagesMeta.title}
                                                </Typography>
                                                {parse(item.node.locationAdvantagesMeta.pointers)}
                                            </Box>
                                        </Box>
                                    ))}
                                </SlickSlider>
                            </TabPanel>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center', mt: { xs: 3, sm: 0 } }}>
                        <Button className="blink" sx={{ border: `1px solid ${secondarycolor}`, color: secondarycolor, "&:hover": { border: `1px solid ${secondarycolor}`, color: secondarycolor } }} onClick={handleOpen} variant="outlined" startIcon={<ShareLocationIcon />}>
                            Get Location on your phone
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent closeBtn={handleClose} />
            </Modal>
        </>
    );
};

export default Plans;